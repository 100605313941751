.table-schedule {
  i {
    color: rgba(19, 124, 189, 0.5);
    font-size: 1.5rem;
  }
  table,
  tr,
  thead,
  th,
  td {
    border: none !important;
  }
  tbody {
    color: #cecece;
    line-height: 3.5rem;
  }
  tr:nth-child(even) {
    background: #1e1f26;
    .bp3-control.bp3-checkbox .bp3-control-indicator {
      border-radius: 50%;
      color: #89898a;
      border: 1px solid;
      font-weight: 400;
      background: #1e1f26;
    }
  }
  tr:nth-child(odd) {
    background: #31323a;
    .bp3-control.bp3-checkbox .bp3-control-indicator {
      border-radius: 50%;
      color: #89898a;
      border: 1px solid;
      font-weight: 400;
      background: #31323a;
    }
  }
  .days {
    .bp3-timepicker-input-row {
      width: 40%;
      .bp3-timepicker-input {
        width: 48%;
      }
    }

    span.bp3-timepicker-arrow-button.bp3-timepicker-minute {
      padding: 0 5rem;
    }
    .bp3-control.bp3-inline {
      margin: 0;
    }
    .bp3-control input:checked ~ .bp3-control-indicator {
      background-color: #5368cd;
      color: #fff;
      border: none;
    }

    .bp3-control-indicator {
      width: 1.6rem;
      height: 1.6rem;
    }
    .bp3-control.bp3-checkbox .bp3-control-indicator {
      border-radius: 50%;
      color: #89898a;
      border: 1px solid;
      font-weight: 400;
      background: #15161e;
    }

    @each $i in $days {
      .bp3-control.bp3-checkbox.bp3-inline:nth-child(#{nth($i, 1)})
        input
        ~ span.bp3-control-indicator::before {
        background-image: unset !important;
        content: "#{nth($i,2)}" !important;
        padding: 1px 7px !important;
        margin: -16px 0;
      }
    }
  }
  img {
    width: 1rem;
    cursor: pointer;
  }
}

hr {
  background: #343a40;
  margin: 15px 0;
}

.filters {
  display: flex;
  .repeatSchedule {
    padding-right: 10px;
  }
  .form-group {
    padding: 0 0.5rem;
    label {
      margin-bottom: 0.3rem;
    }
  }
  select {
    &:focus {
      background: #313239;
      color: #ccc;
    }
  }
}

.rbc-toolbar {
  // padding-top: 0;
  .form-group {
    padding: 0 0.5rem;
    .bp3-inline {
      margin: 0;
    }
  }
  img {
    width: 30px;
    cursor: pointer;
  }
  .rbc-btn-group {
    width: 250px;
  }
  .bp3-control.bp3-inline {
    margin: 0;
  }
  .calendar-view {
    margin-left: auto;
    img {
      width: 3rem;
    }
  }
}

.create {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 2rem;
  img {
    width: 4rem;
    height: 4rem;
    cursor: pointer;
  }
  h3 {
    margin-left: 10px;
  }
}

select.form-control:not([size]):not([multiple]) {
  height: 3rem;
  width: 10rem;
}
.r-input {
  height: 3rem;
}

.input-icon {
  input {
    height: 3rem;
  }
}

.schedule-box {
  .box-container {
    padding-bottom: 1rem;
  }
  .box {
    background: #313239;
    height: 23rem;
    padding: 1rem !important;
    .left-col {
      border-right: 1px solid #636467;
      font-weight: bold;
      .room {
        cursor: pointer;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2),
          0 10px 20px 0 rgba(0, 0, 0, 0.19);
        background: #1e1f25;
        width: 7em;
        height: 3em;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          text-align: center;
          margin: 0;
          color: #fffeee;
        }
      }
      .game {
        padding: 1rem 0 0;
        p {
          font-size: 17px;
          color: #fffeee;
        }
      }
      tbody {
        line-height: 2.5rem;
      }
      th,
      td {
        padding: 0;
      }
      th {
        text-transform: unset;
      }
      td {
        color: #fffeee;
      }
      .section-1 {
        th:first-child {
          width: 12em;
        }
      }
    }
    .right-col {
      .actions {
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        & > * {
          padding: 0 3px;
        }
        i {
          cursor: pointer;
          color: rgb(85, 107, 198);
        }
        img {
          height: 1rem;
          width: 1.5rem;
        }
        img:first-child {
          margin-right: 22px;
        }
      }
      tbody {
        line-height: 2.5rem;
      }
      th,
      td {
        padding: 0;
      }
      th {
        text-transform: unset;
      }
      td {
        color: #fffeee;
      }
      .schedule-row {
        display: flex;
      }
      .offset {
        margin-top: -2em;
        margin-left: -1em;
        font-weight: bold;
        padding-left: 8px;
      }
      .header {
        background: unset;
        p {
          font-size: 17px;
          font-weight: bold;
          color: #fffeee;
          padding-left: 6px;
        }
      }
      .days {
        padding-left: 1em;
        .bp3-control-indicator {
          width: 1.4rem;
          height: 1.4rem;
        }
        .bp3-control.bp3-checkbox .bp3-control-indicator {
          border-radius: 50%;
          color: #434449;
          border: 1px solid;
          font-weight: 400;
          background: #313239;
        }
        .bp3-control input:checked ~ .bp3-control-indicator {
          background-color: #5368cd;
          color: #fff;
          border: none;
        }
        .bp3-control:not(.bp3-align-right) .bp3-control-indicator {
          margin-left: -36px;
        }
        @each $i in $days {
          .bp3-control.bp3-checkbox.bp3-inline:nth-child(#{nth($i, 1)})
            input
            ~ span.bp3-control-indicator::before {
            background-image: unset !important;
            content: "#{nth($i,2)}" !important;
            padding: 15px 4.5px !important;
            margin: -16px 0;
          }
        }
      }
      .respawn {
        p:first-child {
          color: #fff;
          margin: 0;
          font-size: 0.875rem;
          font-weight: 400;
        }
        .respawn-body {
          .respawn-row {
            display: flex;
            justify-content: space-between;
            width: 100%;
            div {
              display: flex;
              align-items: baseline;
              padding: 0;
              img {
                width: 11px;
                height: 11px;
              }
              p {
                padding-left: 15px;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}

.schedule-dialog,
.pause-dialog {
  width: 60vw;
  height: 45vh;
  overflow-y: scroll;
  text-align: center;
  padding: 0;
  .bp3-icon-info-sign {
    display: none;
  }
  .table {
    margin: 0;
  }
  .submit {
    align-self: center;
    margin: 2rem 0 0;
  }
  .current {
    color: green;
    background: darkseagreen;
  }
  .toBeDone {
    color: blue;
  }
}

.pause-dialog {
  i {
    cursor: pointer;
  }
}

.pagination {
  justify-content: flex-end;
  padding-top: 1rem;
  img {
    width: 2rem;
    cursor: pointer;
  }
  .rbc-toolbar-label {
    padding: 0 1rem;
  }
}

.respawn-row {
  width: 38%;
  p {
    display: inline-block;
  }
}

.filters {
  .css-1pcexqc-container {
    top: -3px;
    width: 23rem;
  }
  .css-bg1rzq-control {
    padding: 5px 0;
    background: #1e1f26
      url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAYCAYAAACSuF9OAAAAAXNSR…VrOqiDyChCxYfhc9UuSbJcXZ85cwMpeZs8v4PeZrz3KtYrJnRYv0cLkhgAAAAASUVORK5CYII=)
      no-repeat right 0.75rem center;
    border-style: none;
  }
  .css-bgvzuu-indicatorSeparator {
    display: none;
  }
  svg {
    color: #c4a179;
  }
  .css-1szy77t-control {
    padding: 6px 0;
    box-shadow: none;
    background-color: #1e1f26;
    border-color: #767676;
    &:hover {
      border-color: #767676;
    }
  }
  .css-kj6f9i-menu {
    top: 80%;
    background: #313239;
    color: #ccc;
  }

  .css-dpec0i-option {
    background-color: #bfa27f;
    color: #eee;
    &:active {
      background-color: #bfa27f;
    }
  }
  .css-xo7z33-option {
    background-color: #c4a179;
    &:active {
      background-color: #c4a179;
    }
  }
}
