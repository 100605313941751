.percent-dialog {
  width: 80vw;
  height: 80vw;
  .input-boxes {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    padding-top: 1rem;
  }
  .table {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    padding-top: 2rem;
  }
  .buttons {
    text-align: center;
    padding-top: 1rem;
    .btn {
      width: 20%;
    }
  }
}
