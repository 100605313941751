.scheduler {
  height: 90vh;
  // padding-top: 5vh;
  margin: 0 auto;
  .rbc-calendar {
    margin-bottom: 4rem;
  }
}

.toolbar-scheduler {
  display: flex;
  padding-bottom: 2.5em;
  & > * {
    padding: 1rem;
    padding-left: 0;
    padding-top: 0;
  }
  span:last-child {
    margin-left: auto;
    // padding-top: 2.5em;
    img {
      width: 3rem;
      cursor: pointer;
    }
  }
}

.r-input {
  background-color: rgb(30, 31, 38);
  border: none;
  border-radius: 0;
}

.wrapper {
  max-width: 1310px;
  margin: 0 auto 3rem;
  .row:first-child {
    padding: 1rem 0;
  }
  .home-title {
    // margin: 2rem 0;
  }
}

.rbc-time-content {
  height: 100vh;
}

.rbc-time-content > .rbc-day-slot {
  background: none;
}

.rbc-toolbar .rbc-btn-group {
  width: 220px;
  max-width: 220px;
  span {
    width: 160px;
    max-width: 160px;
    display: inline-block;
  }
  .rbc-toolbar-label {
    color: #fffeee;
  }
}

.rbc-events-container {
  .roomTooltip {
    color: #767677 !important;
    .header-tooltip {
      padding-top: 0.4rem;
      p:first-child {
        font-size: 1.4em;
      }
    }
    .content-tooltip-list {
      span {
        font-size: 1.4em;
      }
      .type {
        font-size: 10px;
      }
    }
    .timeslot-tooltip {
      section {
        p {
          font-size: 1.4em;
          margin: 0;
        }
      }
    }
    .author-tooltip {
      font-size: 1.4em;
      p.view-more {
        color: #337ab7;
      }
    }
    hr {
      margin: 10px 0;
      background: #adadad;
    }
  }
}
.__react_component_tooltip {
  padding: 10px 30px;
}
.rbc-time-header-content {
  .rbc-header {
    color: #fffeee;
  }
}

.react-datepicker-wrapper {
  input {
    background-color: #1e1f26;
    color: #ccc;
  }
}
