$days: (1 "M") (2 "T") (3 "W") (4 "T") (5 "F") (6 "S") (7 "S");

.block-form,
.template-form {
  width: 35%;
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 6rem;
  input:focus,
  select:focus {
    border-color: #767676;
  }
  input {
    height: 3rem;
  }
  hr {
    background: #343a40;
  }
  select.form-control:not([size]):not([multiple]) {
    width: 100%;
  }
  form a {
    color: #c4a179;
    text-decoration: underline;
  }

  .form-group {
    padding: 0.6rem 0;
  }

  .block-detail {
    margin-bottom: 1.6rem;
  }
  .form-label {
    margin-bottom: 1.6rem;
  }
  .days {
    margin-bottom: 1.6rem;
    .bp3-timepicker-input-row {
      width: 40%;
      .bp3-timepicker-input {
        width: 48%;
      }
    }

    span.bp3-timepicker-arrow-button.bp3-timepicker-minute {
      padding: 0 5rem;
    }

    .bp3-control-indicator {
      width: 2.6rem;
      height: 2.6rem;
      outline: none !important;
    }

    .bp3-control input:checked ~ .bp3-control-indicator {
      background-color: #5368cd;
      color: #fff;
      border: none;
    }
    .bp3-control.bp3-checkbox .bp3-control-indicator {
      border-radius: 50%;
      color: #89898a;
      border: 1px solid;
      font-weight: bold;
      background: #15161e;
    }

    @each $i in $days {
      .bp3-control.bp3-checkbox.bp3-inline:nth-child(#{nth($i, 1)})
        input
        ~ span.bp3-control-indicator::before {
        background-image: unset !important;
        content: "#{nth($i,2)}" !important;
        padding: 7px 13px !important;
      }
    }
  }
}

.block-form,
.room-form,
.template-form {
  input {
    background: #313239;
    color: #ccc;
    &:focus {
      background: #313239;
      color: #ccc;
    }
  }
  .custom-select.form-control {
    background-color: #313239;
    &:focus {
      background-color: #313239;
    }
  }
}

.slots,
.rbc-toolbar {
  .bp3-control-indicator {
    background: #15161e;
    border: 1px solid;
    border-radius: 0;
    outline: none !important;
  }
  .bp3-control .bp3-control-indicator,
  .bp3-control .bp3-control-indicator::before {
    height: 1.1em;
    width: 1.1em;
  }
  .bp3-control input:checked ~ .bp3-control-indicator {
    background-color: #5368cd !important;
    border: none;
  }
  .bp3-control.bp3-inline:hover {
    .bp3-control-indicator {
      background: #15161e;
    }
  }
}

.slots {
  label:not(:first-child) {
    width: 9rem;
    font-size: 12px;
  }
}

.custom-select,
.form-control {
  background-color: #1e1f26;
  &:focus {
    border-color: #767676;
  }
}
.custom-select {
  background: #1e1f26 url("../assets/cheveron-down.png") no-repeat right 0.75rem
    center;
  background-size: 0.7rem;
  &:focus {
    border-color: #767676;
  }
}

::placeholder {
  color: #767676 !important;
}
select {
  color: #767676 !important;
}

.btn {
  width: 15.7rem;
  background: #1e1f26;
  color: #bfa27f;
  margin-top: 1rem;
  border-color: #bfa27f;
  border-radius: 0;
  &.submit {
    color: #000;
    background: #bfa27f;
  }
}

.r-input-moment {
  background-color: #15161e;
  border-color: #bfa27f;
}
.u-slider-time .value {
  background-color: #bfa27f;
}
.u-slider-time .handle:after {
  border-color: #bfa27f;
}
.r-time .time {
  background-color: #bfa27f;
  color: #000;
}
.r-time .separater {
  color: #bfa27f;
}
.r-time .time-text {
  color: #fff;
}

input[type="checkbox"]:focus {
  outline: none;
}
.r-calendar .toolbar button {
  background-color: #bfa27f;
  border-color: #bfa27f;
}
.r-calendar .toolbar .current-date {
  color: #bfa27f;
}
.react-textselect {
  border-color: #c4a179;
}
.r-calendar thead td {
  color: #c4a179;
}
.r-calendar .selected-day {
  background-color: #c4a179;
  color: #000;
}
.r-calendar tbody td:hover {
  background: #c4a179;
  border-color: #c4a179;
  color: #000;
}

.room-form {
  .featured-room {
    padding-top: 1rem;
  }
  .css-bg1rzq-control {
    background: #313239
      url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAYCAYAAACSuF9OAAAAAXNSR…VrOqiDyChCxYfhc9UuSbJcXZ85cwMpeZs8v4PeZrz3KtYrJnRYv0cLkhgAAAAASUVORK5CYII=)
      no-repeat right 0.75rem center;
    border-style: none;
  }
  svg {
    color: #c4a179;
  }
  .css-1szy77t-control {
    background-color: #1e1f26;
    border-color: #767676;
    &:hover {
      border-color: #767676;
      color: #fff;
    }
  }
  .css-kj6f9i-menu {
    background: #313239;
    color: #fff;
  }
  .css-dpec0i-option {
    background-color: #bfa27f;
    color: #eee;
    &:active {
      background-color: #bfa27f;
    }
  }
  .css-xo7z33-option {
    background-color: #c4a179;
    &:active {
      background-color: #c4a179;
    }
  }
  .block-div {
    padding-top: 0.5em;
  }
  .custom-select {
    background-color: #313239 !important;
  }
  tbody {
    line-height: 2.5rem;
  }
  th,
  td {
    padding: 0;
    border: none;
  }
  th {
    text-transform: unset;
  }
  td {
    color: #fffeee;
  }
  .section-1 {
    margin: 0;
    th:first-child {
      width: 12em;
    }
  }
  select.form-control:not([size]):not([multiple]) {
    width: 100%;
  }
  .btn.submit {
    width: 100%;
  }
  .respawn {
    padding-left: 5px;
    p:first-child {
      color: #fff;
      margin: 0;
      font-size: 0.875rem;
      font-weight: 400;
    }
    .respawn-body {
      .respawn-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        div {
          display: flex;
          align-items: baseline;
          padding: 0;
          img {
            width: 11px;
            height: 11px;
          }
          p {
            padding-left: 15px;
            font-size: 13px;
          }
        }
      }
    }
  }
  .days {
    padding: 0.5em 1em;
    .bp3-control.bp3-inline {
      margin-right: 0;
    }
    .bp3-control-indicator {
      width: 1.4rem;
      height: 1.4rem;
    }
    .bp3-control.bp3-checkbox .bp3-control-indicator {
      border-radius: 50%;
      color: #434449;
      border: 1px solid;
      font-weight: 400;
      background: #15161e;
    }
    .bp3-control input:checked ~ .bp3-control-indicator {
      background-color: #5368cd;
      color: #fff;
      border: none;
    }
    .bp3-control:not(.bp3-align-right) .bp3-control-indicator {
      margin-left: -36px;
    }
    @each $i in $days {
      .bp3-control.bp3-checkbox.bp3-inline:nth-child(#{nth($i, 1)})
        input
        ~ span.bp3-control-indicator::before {
        background-image: unset !important;
        content: "#{nth($i,2)}" !important;
        padding: 15px 4.5px !important;
        margin: -16px 0;
      }
    }
  }
}

.twirp-error {
  text-align: center;
  color: red;
}

.react-datepicker-wrapper {
  display: block;
  input {
    padding: 10px;
    border: none;
  }
}
