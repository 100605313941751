.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  height: 4rem;
  & > * {
    flex: 1;
  }
  .nav-tabs {
    height: 4rem;
  }
  a {
    text-decoration: none;
    color: #fff;
  }
  .nav-unread {
    right: unset;
  }
  .dropdown-item {
    color: #000;
  }
  .nav-link {
    padding: 0.2rem 0;
  }
  .handle-nav {
    cursor: pointer;
    width: 35px;
    height: 15px;
  }
}

.drawer-content {
  background: #5368cd;
}

.routes {
  padding-top: 20vh;
  & > * {
    margin: 1rem 0;
    color: #000;
  }
  a {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    &:hover {
      color: #000;
    }
  }
  h3 {
    font-size: 1rem;
  }
  .rc-menu-inline {
    .rc-menu-submenu-open {
      .rc-menu-submenu-title {
        .fe-chevron-right {
          transform: rotate(-90deg);
        }
      }
    }
  }
  .rc-menu {
    border: none;
    box-shadow: none;
    .rc-menu-item {
      a {
        color: #000;
      }
    }
    .rc-menu-submenu {
      cursor: pointer;
    }
    .rc-menu-item-selected {
      background: none;
    }
    .rc-menu-submenu-arrow {
      display: none;
    }

    .rc-menu-submenu-title {
      display: flex;
      justify-content: space-between;
    }
    .rc-menu-item-active,
    .rc-menu-submenu-active,
    .rc-menu-submenu-title {
      background: none;
    }
    .rc-menu-sub {
      background: none;
    }
  }
}
