body {
  margin: 0 auto !important;
  background: #15161e !important;
  color: #767677 !important;
  // max-width: 1280px;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  input:focus {
    border-color: #767676;
    outline: none;
  }
}

.row {
  margin: 0 !important;
}
